import { RefObject, useEffect } from "react";

export const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T | null>,
  callback: () => void,
  eventType: "click" | "touchstart" = "click"
) => {
  const closest = (
    element: HTMLElement,
    searchElement: HTMLElement
  ): Node | null => {
    if (element === searchElement) return searchElement;
    for (
      let el: HTMLElement | null = element;
      el !== null;
      el = el.parentElement
    ) {
      if (el === searchElement) {
        return el;
      }
    }
    return null;
  };

  const destroyOutsideClickHandler = () => {
    document.removeEventListener(eventType, handleClick);
  };

  const handleClick = (e: MouseEvent | TouchEvent) => {
    if (!e.target || !ref.current) return;
    const isOutside = closest(e.target as HTMLElement, ref.current) === null;
    if (!isOutside) return;

    callback();
  };

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener(eventType, handleClick);
    });

    return destroyOutsideClickHandler;
  }, []);

  return {
    destroyOutsideClickHandler,
  };
};
