import { RefObject, useEffect, useState } from "react";
import { useThrottle } from "./useThrottle";

export function useIsInViewport(ref?: RefObject<HTMLElement | null>) {
  const [viewportTop, setViewportTop] = useState<number | undefined>(undefined);
  const [viewportBottom, setViewportBottom] = useState<number | undefined>(
    undefined
  );
  const isInViewport =
    viewportTop && viewportTop < 1 && viewportBottom && viewportBottom > 1;

  const handler = useThrottle(() => {
    if (!ref?.current) return;

    const clientRect = ref.current.getBoundingClientRect();
    setViewportTop(clientRect.top);
    setViewportBottom(clientRect.bottom);
  }, 100); // 100msに一度実行

  useEffect(() => {
    if (!ref?.current) return;

    // マウント時にも実行
    handler();
    window.addEventListener("scroll", handler);

    // アンマウント時にイベントリスナーを解除
    return () => window.removeEventListener("scroll", handler);
  }, [handler, ref]);

  return { isInViewport, viewportBottom };
}
