import { IconType } from "@newt/ui";
import { ROUTES } from "@routes";

export type NavItems = {
  icon: IconType;
  label: string;
  href: string;
  prefetch?: boolean;
};

export const MAIN_PAGE_ITEMS: NavItems[] = [
  {
    icon: "tour",
    label: "海外ツアー",
    href: ROUTES.home(),
  },
  {
    icon: "hotel",
    label: "宿・ホテル",
    href: ROUTES.hotelApp.top(),
  },
  {
    icon: "magazine",
    label: "旅行ガイド",
    href: ROUTES.mag(),
  },
];

export const USER_INFO_ITEMS: NavItems[] = [
  {
    icon: "heartOutline",
    label: "お気にいり",
    href: ROUTES.account.wishlist(),
  },
  {
    icon: "travelOutline",
    label: "予約の確認",
    href: ROUTES.reservations.index(),
  },
  {
    icon: "personOutline",
    label: "マイページ",
    href: ROUTES.account.index(),
    prefetch: false,
  },
];

export const FOOTER_ITEMS: {
  title: string;
  items: Omit<NavItems, "icon">[];
}[] = [
  {
    title: "NEWTについて",
    items: [
      { label: "運営会社について", href: "/company" },
      { label: "はじめての方へ", href: "/app" },
      { label: "NEWT Brand", href: "/brand" },
    ],
  },
  {
    title: "NEWTのサービス",
    items: [
      { label: "NEWTポイント", href: "/point" },
      { label: "NEWTアプリで旅行の相談", href: "/travel-concierge" },
      { label: "VIP旅行の相談", href: "/vip" },
    ],
  },
  {
    title: "サポートについて",
    items: [
      {
        label: "ヘルプセンター",
        href: "https://newt.zendesk.com/hc/ja",
      },
    ],
  },
];
