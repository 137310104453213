import Image from "next/image";
import styles from "./AppDownload.module.css";
import AppImage from "./assets/app_image.webp";
import AppImageMobile from "./assets/app_image_mobile.webp";
import AppIcon from "./assets/app_icon.png";
import AppDownloadAndroid from "./assets/app_download_android.png";
import AppDownloadIos from "./assets/app_download_ios.png";
import App2DCode from "./assets/app_2d_code.png";
import { Button, Stack, Text } from "@newt/ui";
import { useBreakPoints } from "@newt/ui";
import { FC } from "react";
import { useAppLink } from "@utils/useAppLink";
import { UtmCampaign } from "@utils/dynamicLink";

const FOOTER_IOS_DOWNLOAD =
  "https://app.adjust.com/kbydh1m?redirect_macos=https%3A%2F%2Fapps.apple.com%2Fjp%2Fapp%2Fid1585901351&redirect_windows=https%3A%2F%2Fapps.apple.com%2Fjp%2Fapp%2Fid1585901351";
const FOOTER_ANDROID_DOWNLOAD =
  "https://app.adjust.com/o6bodw2?redirect_macos=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnet.newt&redirect_windows=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnet.newt";

interface PcActionProps {
  utmCampaign?: UtmCampaign;
}

const PcAction: FC<PcActionProps> = () => {
  return (
    <Image
      className={styles.app2DCode}
      src={App2DCode.src}
      alt=""
      width={100}
      height={100}
      style={{
        maxWidth: "100%",
        height: "auto",
      }}
    />
  );
};

interface SpActionProps {
  appUrl?: string;
  utmCampaign?: UtmCampaign;
}

const SpAction: FC<SpActionProps> = ({ utmCampaign, appUrl }) => {
  const link = useAppLink({
    path: appUrl,
    utmCampaign,
    utmContent: "footer_banner",
  });
  return (
    <Button href={link} size="lg">
      アプリをダウンロードする
    </Button>
  );
};

interface AppPreviewProps {
  tabletWithDown: boolean;
}

const AppPreview: FC<AppPreviewProps> = ({ tabletWithDown }) => {
  const imageProps = tabletWithDown
    ? { src: AppImageMobile.src, width: 289, height: 245 }
    : { src: AppImage.src, width: 212, height: 269 };

  return (
    <Image
      alt="NEWTアプリ画面イメージ"
      {...imageProps}
      style={{
        maxWidth: "100%",
        height: "auto",
      }}
    />
  );
};

interface AppDownloadProps {
  readonly text?: string;
  readonly variant?: "default" | "primary";
  readonly appUrl?: string;
  readonly utmCampaign?: UtmCampaign;
}

export const AppDownload: FC<AppDownloadProps> = ({
  text,
  variant = "default",
  appUrl,
  utmCampaign,
}) => {
  const breakPoints = useBreakPoints();

  const fdl = useAppLink({ utmCampaign, utmContent: "footer_banner" }) || "";
  // (pc) adjust  → app store with redirect_macos or redirect_windows key
  // (sp) adjust  → firebase dynamic link with redirect key → open app if installed, otherwise redirect to app store
  const iosLink = FOOTER_IOS_DOWNLOAD + "&redirect=" + encodeURIComponent(fdl);
  const androidLink =
    FOOTER_ANDROID_DOWNLOAD + "&redirect=" + encodeURIComponent(fdl);

  return (
    <section className={`${styles.root} ${styles[`variant--${variant}`]}`}>
      <div className={styles.downloadContainer}>
        <header className={styles.header}>
          <figure className={styles.figure}>
            <AppPreview tabletWithDown={breakPoints.tabletWithDown} />
          </figure>
          <div className={styles.content}>
            {text && (
              <>
                <Text
                  align={{ mobile: "center", laptop: "left" }}
                  as="h1"
                  size="lg"
                  weight="bold"
                >
                  {text}
                </Text>
              </>
            )}
            <Stack align="center" spacing="3x">
              <img
                className={styles.appIconImg}
                src={AppIcon.src}
                alt=""
                loading="lazy"
              />
              <Text
                as="h2"
                weight={"bold"}
                size={{ mobile: "base", tablet: "lg" }}
              >
                NEWT（ニュート）
                <br />
                かしこい、おトク、旅行アプリ
              </Text>
            </Stack>
            <div className={styles.downloadWrapper}>
              <a href={iosLink} className={styles.download}>
                <Image
                  src={AppDownloadIos.src}
                  alt=""
                  width={150}
                  height={48}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </a>
              <a href={androidLink} className={styles.download}>
                <Image
                  src={AppDownloadAndroid.src}
                  alt=""
                  width={150}
                  height={48}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </a>
            </div>
          </div>
        </header>
        <div className={styles.action}>
          {breakPoints.tabletWithDown ? (
            <SpAction utmCampaign={utmCampaign} appUrl={appUrl} />
          ) : (
            <PcAction utmCampaign={utmCampaign} />
          )}
        </div>
      </div>
    </section>
  );
};
