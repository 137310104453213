import { API_HOST, APP_ENV } from "@constants";
import {
  GlobalNavList,
  Icon,
  Stack,
  Tag,
  Header as UIHeader,
  useBreakPoints,
} from "@newt/ui";
import { ROUTES } from "@routes";
import { User as UserType, useAuth } from "@utils/auth";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, ReactNode } from "react";
import styles from "./Header.module.css";
import { User, useHeaderMenu } from "./components";
import { SearchBoxInHeader } from "./components/SearchBoxInHeader";
import { FOOTER_ITEMS, MAIN_PAGE_ITEMS, USER_INFO_ITEMS } from "./navItems";
import { TopPageCatchphrase } from "../TopPageCatchphrase";
import { useSupportChat } from "@utils/chat/hooks/useSupportChat";

interface GetApiEnv {
  (): {
    env: string;
    color: "default" | "primary" | "danger" | "disabled" | "blue";
  };
}

const getApiEnv: GetApiEnv = () => {
  const dev = API_HOST.match(/(dev\d*).api.newt.net/);
  if (dev) {
    return { env: dev[1], color: "blue" };
  }
  if (API_HOST.includes("local")) return { env: "local", color: "default" };
  if (API_HOST.includes("git")) return { env: "dev", color: "blue" };
  if (API_HOST.includes("staging")) return { env: "staging", color: "primary" };
  if (API_HOST === "https://api.newt.net/graphql")
    return { env: "production", color: "danger" };
  return { env: "unknown", color: "disabled" };
};

interface HeaderProps {
  hasNavigation?: boolean;
  noFollow?: boolean;
  catchphrase?: string;
}

const GlobalNav = () => {
  const router = useRouter();
  const isTopPage = router.pathname === ROUTES.home();

  const isExceptionPath = [
    "/[country]/hotel/[hotelId]",
    "/[country]/[city]/hotel/[hotelId]",
  ].includes(router.pathname);

  const NAV_ITEMS = [
    {
      label: "海外ツアー",
      isActive: !isExceptionPath,
      href: ROUTES.home(),
    },
    {
      label: "宿・ホテル",
      isActive: isExceptionPath,
      href: ROUTES.hotelApp.top(),
    },
    {
      label: "旅行ガイド",
      isActive: false,
      href: ROUTES.mag(),
    },
  ];

  return (
    <GlobalNavList
      className={styles.nav}
      itemClassName={isTopPage ? styles.removeUnderline : ""}
      items={NAV_ITEMS}
    />
  );
};

interface HeaderRightProps {
  user: UserType | undefined;
  menuButton: ReactNode;
  isLoading: boolean;
  catchphrase?: string;
}

const HeaderRight: FC<HeaderRightProps> = ({
  menuButton,
  user,
  isLoading,
  catchphrase,
}) => {
  const { onOpen, unreadMessages } = useSupportChat();
  const isNotProductEnv =
    APP_ENV === "local" || APP_ENV === "preview" || APP_ENV === "staging";

  return (
    <Stack align="center">
      <div className={styles.pc}>
        <TopPageCatchphrase text={catchphrase} variant="pc" size="sm" />
      </div>
      {isNotProductEnv ? (
        <div className={styles.right}>
          <div>
            <Tag color={getApiEnv().color}>{getApiEnv().env}</Tag>
          </div>
          <User
            onOpenSupportChat={onOpen}
            unreadSupportChatMessages={unreadMessages}
          />
        </div>
      ) : (
        <div className={styles.right}>
          <User
            onOpenSupportChat={onOpen}
            unreadSupportChatMessages={unreadMessages}
          />
        </div>
      )}
      <div className={styles.menuOpenButton}>
        {!isLoading && (
          <Link
            data-testid="header-mypage-button"
            className={`${styles.myPageButton} ${unreadMessages ? styles.hasBadge : ""}`}
            prefetch={false} // avoid prefetch huge size library, libphonenumber TODO: replace it.
            href={
              user
                ? ROUTES.account.index()
                : ROUTES.signin.index({ redirectTo: ROUTES.account.index() })
            }
            aria-label="マイページを開く"
          >
            <Icon icon="personOutline" size="md" />
          </Link>
        )}
        {menuButton}
      </div>
    </Stack>
  );
};

export const Header: FC<HeaderProps> = ({
  hasNavigation = false,
  noFollow = false,
  catchphrase,
}) => {
  const breakpoint = useBreakPoints();
  const router = useRouter();

  const regex = /^\/tour\/.*\/\[tourId\]/;
  const inTourDetailPage = regex.test(router.pathname);
  const { MenuOpenButton, HeaderMenu } = useHeaderMenu();

  // ユーザー情報の保持がwebとhotelで違うため、HeaderMenuにpropsとして渡す用
  const auth = useAuth();

  return hasNavigation ? (
    <>
      <UIHeader
        noFollow={noFollow}
        shadow={noFollow || breakpoint.mobileOnly}
        right={
          <HeaderRight
            menuButton={<MenuOpenButton />}
            user={auth.user}
            isLoading={auth.isLoading}
            catchphrase={catchphrase}
          />
        }
        center={
          // toggle with css to avoid flickering
          <div className={styles.pc}>
            {inTourDetailPage ? (
              <SearchBoxInHeader globalNav={<GlobalNav />} />
            ) : (
              <div className={styles.center}>
                <GlobalNav />
              </div>
            )}
          </div>
        }
        menu={
          <HeaderMenu
            isLogin={!!auth.user}
            navItems={{
              mainPage: MAIN_PAGE_ITEMS,
              userInfo: USER_INFO_ITEMS,
              footer: FOOTER_ITEMS,
            }}
            signIn={ROUTES.signin.index}
          />
        }
      />

      <div className={styles.sp}>
        <GlobalNav />
      </div>
    </>
  ) : (
    <UIHeader
      transparent={false}
      noFollow={noFollow}
      right={
        <HeaderRight
          menuButton={<MenuOpenButton />}
          user={auth.user}
          isLoading={auth.isLoading}
        />
      }
      menu={
        <HeaderMenu
          isLogin={!!auth.user}
          navItems={{
            mainPage: MAIN_PAGE_ITEMS,
            userInfo: USER_INFO_ITEMS,
            footer: FOOTER_ITEMS,
          }}
          signIn={ROUTES.signin.index}
        />
      }
    />
  );
};
