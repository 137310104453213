import Image from "next/image";
import styles from "./Hero.module.css";
import { Stack } from "../Stack";
import { DownloadAppButton } from "../DownloadAppButton";
import { FC } from "react";
import { BreakPoints, useBreakPoints } from "../../hooks/useBreakPoints";

function Action({
  iosLink,
  androidLink,
}: {
  iosLink?: string;
  androidLink?: string;
}) {
  return (
    <Stack direction="row" spacing="6x" as="div" justify="center">
      <DownloadAppButton
        variant="ios"
        className={styles.download}
        loading="eager"
        link={iosLink}
      />
      <DownloadAppButton
        variant="android"
        className={styles.download}
        loading="eager"
        link={androidLink}
      />
    </Stack>
  );
}

interface ImgProps {
  src: string;
  width: number;
  height: number;
}
interface HeroProps {
  kvImgPcSrc: string;
  kvImgSpSrc: string;
  titleImgPcProps: ImgProps;
  titleImgSpProps: ImgProps;
  mockImgSrc: string;
  iosLink?: string;
  androidLink?: string;
}

export const Hero: FC<HeroProps> = (props) => {
  const {
    kvImgPcSrc,
    kvImgSpSrc,
    titleImgPcProps,
    titleImgSpProps,
    mockImgSrc,
    iosLink,
    androidLink,
  } = props;
  const { tabletWithUp } = useBreakPoints(); // レイアウトシフトを避けるため、使用を最小限にする。

  return (
    <header className={styles.root}>
      <picture>
        <source
          className={styles.image}
          media={`(max-width:${BreakPoints.MOBILE_MAX})`}
          srcSet={`${kvImgSpSrc} 767w`}
          sizes="100vw"
        />

        <Image
          src={kvImgPcSrc}
          alt="メインビジュアル"
          loading="eager"
          fill={true}
          style={{
            objectFit: "cover",
            zIndex: -1,
          }}
        />
      </picture>
      <div className={styles.pcWrapper}>
        <div className={styles.pcFlex}>
          <div className={styles.pcLeft}>
            <div
              style={{
                height: `${titleImgPcProps.height + 72}px`,
                width: `${titleImgPcProps.width}px`,
              }}
            >
              {tabletWithUp && (
                <h1 className={styles.h1Title}>
                  <Image
                    className={styles.pcTitle}
                    alt="かしこい、おトク、旅行アプリ │ おトクな海外旅行・国内旅行のツアーやホテル予約はNEWT(ニュート)"
                    loading="eager"
                    {...titleImgPcProps}
                  />
                </h1>
              )}
            </div>
            <div className={styles.pcAction}>
              <Action iosLink={iosLink} androidLink={androidLink} />
            </div>
          </div>
          <div className={styles.pcRight}>
            <Image
              src={mockImgSrc}
              alt="アプリ画像"
              height={272}
              width={290}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.spWrapper}>
        <div className={styles.spFlex}>
          <div className={styles.spLeft}>
            {!tabletWithUp && (
              <h1 className={styles.h1Title}>
                <Image
                  className={styles.spTitle}
                  alt="かしこい、おトク、旅行アプリ │ おトクな海外旅行・国内旅行のツアーやホテル予約はNEWT(ニュート)"
                  src={titleImgSpProps.src}
                  fill={true}
                  style={{ objectFit: "contain" }}
                />
              </h1>
            )}
          </div>
          <div className={styles.spRight}>
            <Image
              src={mockImgSrc}
              alt="アプリ画像"
              loading="eager"
              fill={true}
              style={{ objectFit: "contain" }}
            />
          </div>
        </div>
        <Action iosLink={iosLink} androidLink={androidLink} />
      </div>
    </header>
  );
};
