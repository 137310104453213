import { ChangeEventHandler, FC, ReactNode } from "react";
import { Icon, IconType } from "../Icon";
import styles from "./ChipBasic.module.css";

interface ChipBasicProps {
  label: ReactNode;
  value?: number | string;
  isChecked?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  iconLeft?: IconType;
  iconRight?: IconType;
  iconLeftUrl?: string;
  size?: "sm" | "base" | "md";
  disabled?: boolean;
}

export const ChipBasic: FC<ChipBasicProps> = (props) => {
  const {
    label,
    value,
    isChecked,
    disabled,
    iconLeft,
    iconRight,
    iconLeftUrl,
    size = "md",
    onChange,
  } = props;

  const classes = [
    styles.root,
    isChecked ? styles.checked : "",
    disabled ? styles.disabled : "",
  ].join(" ");
  const bodyClasses = [styles.body, styles[`size--${size}`]].join(" ");

  return (
    <label className={classes}>
      <input
        type="checkbox"
        className={styles.input}
        value={value}
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <div className={bodyClasses}>
        {iconLeftUrl ? (
          <span className={styles.icon}>
            <img
              src={iconLeftUrl}
              alt=""
              className={styles.iconImage}
              width={16}
              height={16}
            />
          </span>
        ) : (
          iconLeft && (
            <span className={styles.icon}>
              <Icon icon={iconLeft} size="sm" color="gray-80" />
            </span>
          )
        )}
        <span className={styles.label}>{label}</span>
        {iconRight && (
          <span className={styles.icon}>
            <Icon icon={iconRight} size="sm" color="gray-80" />
          </span>
        )}
      </div>
    </label>
  );
};
