import { FC } from "react";
import { FontSizes, Text } from "@newt/ui";
interface TopPageCatchphraseProps {
  text?: string;
  variant?: "sp" | "pc";
  size?: FontSizes;
}

export const TopPageCatchphrase: FC<TopPageCatchphraseProps> = ({
  text,
  variant,
  size,
}) => {
  return (
    text && (
      <Text
        as="h1"
        color={variant === "pc" ? "primary" : "default"}
        weight="bold"
        align="center"
        size={size}
      >
        {text}
      </Text>
    )
  );
};
