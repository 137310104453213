import { FC, ReactNode } from "react";
import { Icon } from "../Icon";
import { Paragraph } from "../Paragraph";
import styles from "./Alert.module.css";

export enum AlertVariant {
  NORMAL = "normal",
  WARNING = "warning",
  POSITIVE = "positive",
}

const COLOR = {
  [AlertVariant.NORMAL]: "warning",
  [AlertVariant.WARNING]: "danger",
  [AlertVariant.POSITIVE]: "primary",
} as const;

interface AlertProps {
  readonly className?: string;
  readonly title: string;
  readonly body?: string;
  readonly remarks?: ReactNode;
  readonly variant?: AlertVariant;
  readonly type?: "normal" | "card";
}

export const Alert: FC<AlertProps> = ({
  className = "",
  title,
  body,
  remarks,
  variant = AlertVariant.NORMAL,
  type = "normal",
}) => {
  const rootClasses = [
    className,
    styles.root,
    styles[`variant--${variant}`],
    type === "normal" && styles.normal,
    type === "card" && styles.card,
  ].join(" ");

  return (
    <div className={rootClasses}>
      <div className={styles.titleContainer}>
        <div className={styles.iconWrapper}>
          <Icon size="sm" color={COLOR[variant]} icon="alertCircle" />
        </div>
        <div className={`${styles.title} ${type === "card" && styles.card}`}>
          <Paragraph
            weight="bold"
            color={COLOR[variant]}
            size={type === "card" ? "sm" : "base"}
          >
            {title}
          </Paragraph>
        </div>
      </div>
      {(body || remarks) && (
        <div className={styles.body}>
          <Paragraph weight="bold">{body}</Paragraph>
          <Paragraph size="sm">{remarks}</Paragraph>
        </div>
      )}
    </div>
  );
};
