import { gtm } from "../gtm";

export type ClickModuleId =
  | "search_from_hotel_navigationbar"
  | "item_filter_modal_chip"
  | "item_filter_chip"
  | "item_filter_submit_button"
  | "spin_gacha"
  | "spin_toa_gacha"
  | "spin_gacha1"
  | "spin_gacha2"
  | "phone_auth_button"
  | "google_auth_button"
  | "apple_auth_button"
  | "mypage_contact_cell"
  | "mypage_contact_button"
  | "tour_detail_contact_button"
  | "tour_list_contact_button"
  | "area_lp_contact_button"
  | "seo_tour_list_contact_button"
  | "spot_list_contact_button"
  | "tour_list_recent_filter";

export interface ClickModuleEventVariables {
  module_id: ClickModuleId;
  module_name: string;
  is_newt_app?: boolean;
}

export const clickModuleEvent = (variables?: ClickModuleEventVariables) => {
  gtm.pushEvent("click_module", variables);
};
