import { forwardRef, FC, JSX } from "react";
import styles from "./Input.module.css";

interface InputProps extends Omit<JSX.IntrinsicElements["input"], "ref"> {
  error?: boolean;
  children?: never;
}

export const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  ({ className, style, error, ...props }, ref) => (
    <input
      type="text"
      {...props}
      ref={ref}
      className={[
        styles.input,
        error ? styles.error : "",
        className ?? "",
      ].join(" ")}
      style={style}
    />
  )
);
Input.displayName = "Input";
