import { ChangeEventHandler, FC, ReactNode, useState } from "react";
import styles from "./DropdownSelect.module.css";
import { Text } from "../../../Text";
import { Icon } from "../../../Icon";

export interface DropdownSelectOptionParam<Value = string> {
  label: string | ReactNode;
  subLabel?: string;
  value: Value;
  disabled?: boolean;
  imageUrl?: string;
  options?: DropdownSelectOptionParam[];
}

export interface DropdownSelectOptionProps {
  name: string;
  option: DropdownSelectOptionParam;
  checked: boolean;
  onChange?: (value: string) => void;
  className?: string;
}

export interface DropdownSelectOptionGroup {
  label: string;
  options: DropdownSelectOptionParam[];
}

export interface DropdownSelectProps {
  name: string;
  checked?: string;
  options?: DropdownSelectOptionParam[];
  optionGroups?: DropdownSelectOptionGroup[];
  onChange?: (v: string) => void;
  withBorder?: boolean;
  className?: string;
}

const Option = ({
  option,
  checked,
  onChange,
  name,
  className,
}: DropdownSelectOptionProps) => {
  const { value, disabled, imageUrl, label } = option;
  const id = `${name}_${value}`;

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onChange) onChange(value);
  };

  return (
    <label
      htmlFor={id}
      className={`${styles.option} ${
        disabled ? styles["option--disabled"] : ""
      } ${className}`}
    >
      <input
        id={id}
        disabled={disabled}
        className={styles.optionInput}
        type="radio"
        value={value}
        checked={checked}
        name={name}
        onChange={handleChange}
      />
      {imageUrl && (
        <div className={styles.optionImage}>
          <img src={imageUrl} alt={id} />
        </div>
      )}
      <div className={styles.optionLabel}>
        {typeof label === "string" ? (
          <Text color={disabled ? "gray" : "default"} weight="bold">
            {label}
          </Text>
        ) : (
          label
        )}
      </div>
    </label>
  );
};

export const DropdownSelect: FC<DropdownSelectProps> = ({
  name,
  options,
  optionGroups,
  checked,
  onChange,
  withBorder,
  className,
}) => {
  const handleChange = (value: string) => {
    if (onChange) onChange(value);
  };

  const getOption = (option: DropdownSelectOptionParam) => (
    <Option
      name={name}
      option={option}
      checked={option.value === checked}
      onChange={handleChange}
      className={className}
    />
  );

  const optionsClasses = [
    styles.options,
    withBorder ? styles["options--with-border"] : "",
    className,
  ].join(" ");

  const OptionCollapse = ({
    label,
    options,
    index,
  }: {
    label: string | ReactNode;
    options: DropdownSelectOptionParam<string>[];
    index: number;
  }) => {
    const [isOpen, setIsOpen] = useState(
      options.some(
        (option) =>
          option.value === checked ||
          option.options?.some((childOption) => childOption.value === checked)
      )
    );

    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
    };

    return (
      <li key={index}>
        <div className={styles.optionCollapse} onClick={handleClick}>
          {label}
          <Icon icon={isOpen ? "chevronUp" : "chevronDown"} color="gray-80" />
        </div>
        <ul
          className={[
            optionsClasses,
            isOpen
              ? styles.optionCollapseListActive
              : styles.optionCollapseListInActive,
          ].join(" ")}
        >
          {options.map((option) => (
            <li key={option.value} className={styles.optionCollapseListItem}>
              {getOption(option)}
              {option.options &&
                option.options.map((childOption) => (
                  <ul key={childOption.value}>
                    <li
                      key={childOption.value}
                      className={styles.optionCollapseListItem}
                    >
                      {getOption(childOption)}
                    </li>
                  </ul>
                ))}
            </li>
          ))}
        </ul>
      </li>
    );
  };

  if (options) {
    return (
      <div className={styles.root}>
        <ul className={optionsClasses}>
          {options.map((option, index) => {
            if (option.options) {
              return (
                <OptionCollapse
                  key={index}
                  label={option.label}
                  options={option.options}
                  index={index}
                />
              );
            }
            return <li key={option.value}>{getOption(option)}</li>;
          })}
        </ul>
      </div>
    );
  }

  if (optionGroups) {
    return (
      <div className={styles.root}>
        <ul className={styles.optionGroups}>
          {optionGroups.map((group) => (
            <li key={group.label}>
              <dl className={styles.optionGroup}>
                <dt>
                  <span className={styles.optionGroupLabel}>{group.label}</span>
                </dt>
                <dd>
                  <ul className={optionsClasses}>
                    {group.options.map((option, index) => {
                      if (option.options) {
                        return (
                          <OptionCollapse
                            key={index}
                            label={option.label}
                            options={option.options}
                            index={index}
                          />
                        );
                      }
                      return <li key={option.value}>{getOption(option)}</li>;
                    })}
                  </ul>
                </dd>
              </dl>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};
