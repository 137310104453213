import { Container, useBreakPoints } from "@newt/ui";
import { ROUTES } from "@routes";
import { FC } from "react";
import hotelBanner from "./assets/hotel_banner.webp";
import hotelBannerMobile from "./assets/hotel_banner_mobile.webp";
import magBanner from "./assets/mag_banner.webp";
import magBannerMobile from "./assets/mag_banner_mobile.webp";
import Image from "next/image";

interface BannerProps {
  type: "hotel" | "mag";
}

const BANNER_PARAMS = {
  hotel: {
    srcPc: hotelBanner,
    srcMobile: hotelBannerMobile,
    altText:
      "全世界70万軒以上🌟宿・ホテルの予約 ツアーにないエリアもラインナップ！",
    href: ROUTES.hotelApp.top(),
  },
  mag: {
    srcPc: magBanner,
    srcMobile: magBannerMobile,
    altText:
      "70カ国以上を紹介📖 海外旅行ガイド もっと旅行がたのしくなるヒントをお届け！",
    href: ROUTES.mag(),
  },
};

export const Banner: FC<BannerProps> = ({ type }) => {
  const { mobileOnly } = useBreakPoints();
  const { srcPc, srcMobile, altText, href } = BANNER_PARAMS[type];

  return (
    <Container>
      <a href={href} target="_blank" rel="noreferrer">
        <Image
          width={mobileOnly ? 670 : 1032}
          height={mobileOnly ? 380 : 192}
          sizes="100vw"
          style={{
            width: "100%",
            height: "auto",
          }}
          src={mobileOnly ? srcMobile : srcPc}
          alt={altText}
          loading="lazy"
        />
      </a>
    </Container>
  );
};
