import { FC } from "react";
import { NavSubGroup, Footer as UIFooter } from "@newt/ui";
import { gql } from "graphql-request";
import { Footer_DestinationConnectionFragment } from "@graphql/types";

export const FOOTER_DESTINATION_FRAGMENT = gql`
  fragment Footer_Destination on Destination {
    id
    name
    country: children(
      filters: { DESTINATION_STATUS: [PUBLIC], ORDER_BY: POSITION_ASC }
    ) {
      id
      links {
        areaLp {
          title
          url
        }
      }
      city: children(
        filters: { DESTINATION_STATUS: [PUBLIC], ORDER_BY: POSITION_ASC }
      ) {
        id
        links {
          areaLp {
            title
            url
          }
        }
      }
    }
  }
  fragment Footer_DestinationConnection on DestinationConnection {
    edges {
      node {
        ...Footer_Destination
      }
    }
  }
`;

type FooterProps = {
  destinations?: Footer_DestinationConnectionFragment;
};

export const Footer: FC<FooterProps> = ({ destinations }) => {
  const areaLpGroup = {
    id: "areaLp",
    label: "海外旅行",
    subGroups: [] as NavSubGroup[],
    allowToggle: true,
  };

  const staticTlGroup = {
    id: "staticTl",
    label: "海外ツアー",
    subGroups: [] as NavSubGroup[],
    allowToggle: true,
  };

  // IMPROVE: fix on backend to get Static TL url
  destinations?.edges.forEach((destination) => {
    const areaLpSubGroup: NavSubGroup = {
      id: String(destination.node.id),
      label: destination.node.name,
      items: [],
    };

    const staticTlSubGroup: NavSubGroup = {
      id: String(destination.node.id),
      label: destination.node.name,
      items: [],
    };

    destination.node.country.forEach((child) => {
      if (child.links?.areaLp?.title && child.links?.areaLp?.url) {
        areaLpSubGroup.items.push({
          label: child.links.areaLp.title,
          href: child.links.areaLp.url,
        });
        staticTlSubGroup.items.push({
          label: child.links.areaLp.title.replace("旅行", "ツアー"),
          href: `/tour${child.links.areaLp.url}`,
        });
      }

      child.city.forEach((grandChild) => {
        if (
          child.links?.areaLp?.title !== grandChild.links?.areaLp?.title &&
          grandChild.links?.areaLp?.title &&
          grandChild.links?.areaLp?.url
        ) {
          areaLpSubGroup.items.push({
            label: grandChild.links.areaLp.title,
            href: grandChild.links.areaLp.url,
          });
          staticTlSubGroup.items.push({
            label: grandChild.links.areaLp.title.replace("旅行", "ツアー"),
            href: `/tour${grandChild.links.areaLp.url}`,
          });
        }
      });
    });
    areaLpGroup.subGroups.push(areaLpSubGroup);
    staticTlGroup.subGroups.push(staticTlSubGroup);
  });

  return (
    <UIFooter
      customNavGroupsArr={destinations && [[areaLpGroup], [staticTlGroup]]}
    />
  );
};
