import { CSSProperties, FC, useEffect, useState } from "react";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { Modal } from "../Modal";
import { useTimeout } from "../../hooks/useTimeout";
import styles from "./SalesModal.module.css";

const MODAL_SESSION_KEY = "sales-modal-should-open";
const VISIT_DURATION = 10 * 1000;

const emojiFont: CSSProperties = {
  fontFamily:
    "Twemoji Mozilla,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji,EmojiOne Color,Android Emoji,sans-serif",
};

const HARDCODED_SALE = {
  title: "メガサマ割！宿・ホテル10%OFF",
  description: (
    <>
      メガサマーセール開催！宿・ホテル10%OFFクーポン配布中
      <span style={emojiFont}>🎟</span> いつでも5%ポイント還元とWでおトク！
    </>
  ),
  imageSrc: "https://newt.imgix.net/product/megasale_modal.png",
  link: "https://newt.net/sale-fes2306-htl",
  startDateTime: new Date("2023-06-05T00:00:00Z"),
  endDateTime: new Date("2023-07-31T14:59:00Z"),
} as const;

export const SalesModal: FC = () => {
  const sale = HARDCODED_SALE;
  const currentDateTime = new Date();
  const isSalePeriod =
    sale.startDateTime <= currentDateTime && currentDateTime < sale.endDateTime;

  const [shouldOpenInSession, setShouldOpenInSession] = useSessionStorage(
    MODAL_SESSION_KEY,
    true
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const timeout = useTimeout(VISIT_DURATION);

  const handleClose = () => {
    setShouldOpenInSession(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (shouldOpenInSession) {
      const cacheImg = new Image();
      cacheImg.src = sale.imageSrc; // preload image to cache
      timeout.exec(() => setIsModalOpen(true));
    }
  }, []);

  if (!isSalePeriod) {
    return null;
  }

  return (
    <div id="sales-modal-wrapper">
      <Modal
        isActive={isModalOpen}
        title={sale.title}
        onClose={handleClose}
        fullScreen={false}
      >
        <section>
          <a href={sale.link} target="_blank" rel="noopener noreferrer">
            <img
              src={sale.imageSrc}
              className={styles.image}
              alt={sale.title}
            />
          </a>
          <p className={styles.description}>{sale.description}</p>
        </section>
        <footer className={styles.footer}>
          <a href={sale.link} target="_blank" rel="noopener noreferrer">
            <div className={styles.action}>詳しくみる</div>
          </a>
          <div className={styles.close}>
            <button onClick={handleClose}>とじる</button>
          </div>
        </footer>
      </Modal>
    </div>
  );
};
