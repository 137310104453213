import { FC } from "react";

export const LogoHorizontal: FC = () => {
  return (
    <svg
      width="144"
      height="33"
      viewBox="0 0 144 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.3459 27.7871H80.4238C80.9678 27.7871 81.407 27.3479 81.407 26.8039V10.3641C81.407 9.82004 80.9678 9.38086 80.4238 9.38086H77.4807C76.9367 9.38086 76.4975 9.82004 76.4975 10.3641V19.541L67.1112 9.83315C66.8359 9.54473 66.4492 9.38086 66.0493 9.38086H63.9715C63.4274 9.38086 62.9883 9.82004 62.9883 10.3641V26.8104C62.9883 27.3545 63.4274 27.7937 63.9715 27.7937H66.9145C67.4586 27.7937 67.8977 27.3545 67.8977 26.8104V17.6335L77.2841 27.3414C77.5594 27.6298 77.9461 27.7937 78.3459 27.7937V27.7871Z"
        fill="#00CD68"
      />
      <path
        d="M85.4917 27.7846H98.4896C99.0337 27.7846 99.4728 27.3454 99.4728 26.8014V24.4088C99.4728 23.8647 99.0337 23.4256 98.4896 23.4256H89.3196V20.6856H96.1824C96.7264 20.6856 97.1656 20.2464 97.1656 19.7024V17.3098C97.1656 16.7658 96.7264 16.3266 96.1824 16.3266H89.3196V13.7243H98.2274C98.7715 13.7243 99.2106 13.2851 99.2106 12.741V10.3485C99.2106 9.80441 98.7715 9.36523 98.2274 9.36523H85.4917C84.8952 9.36523 84.4102 9.8503 84.4102 10.4468V26.6965C84.4102 27.293 84.8952 27.778 85.4917 27.778V27.7846Z"
        fill="#00CD68"
      />
      <path
        d="M133.715 27.7859H136.658C137.202 27.7859 137.641 27.3467 137.641 26.8026V13.7321H143.009C143.553 13.7321 143.992 13.2929 143.992 12.7488V10.3563C143.992 9.81223 143.553 9.37305 143.009 9.37305H127.389C126.845 9.37305 126.406 9.81223 126.406 10.3563V12.7488C126.406 13.2929 126.845 13.7321 127.389 13.7321H132.732V26.8026C132.732 27.3467 133.171 27.7859 133.715 27.7859Z"
        fill="#00CD68"
      />
      <path
        d="M116.134 27.7859H119.019C119.569 27.7859 120.048 27.4057 120.166 26.8682L123.908 10.4546C124.033 9.904 123.613 9.37305 123.05 9.37305H120.126C119.569 9.37305 119.091 9.76634 118.973 10.3104L117.164 18.917L115.138 10.2842C115.014 9.75323 114.535 9.37305 113.991 9.37305H111.736C111.186 9.37305 110.714 9.75323 110.589 10.2842L108.57 18.917L106.755 10.3104C106.637 9.76634 106.158 9.37305 105.601 9.37305H102.678C102.107 9.37305 101.688 9.89744 101.819 10.4546L105.575 26.8682C105.699 27.4057 106.171 27.7859 106.722 27.7859H109.593C110.137 27.7859 110.609 27.4122 110.74 26.8813L112.864 18.0518L114.987 26.8813C115.112 27.4122 115.59 27.7859 116.134 27.7859Z"
        fill="#00CD68"
      />
      <path
        d="M44.2574 10.3496C43.4315 10.9723 42.6057 11.5492 41.7798 12.0932C41.6946 12.1457 41.5897 12.1325 41.5241 12.0539C37.1718 6.76405 31.1284 5.45962 27.3005 5.72837C19.5528 6.27243 11.4119 10.5921 6.44344 17.8943C6.39755 17.9664 6.30579 17.9992 6.22058 17.973C5.65032 17.7895 5.09317 17.5862 4.55569 17.3765C4.0182 17.1667 3.51349 17.8157 3.88711 18.2811C4.75888 19.3692 5.79452 20.3852 6.97437 21.2832C7.09235 21.375 7.07269 21.552 6.94159 21.6175C5.23737 22.4238 3.08088 23.0924 0.550771 23.23C-0.0719249 23.2628 -0.209573 24.115 0.360685 24.3509C4.54258 26.088 9.23573 26.1077 13.4176 24.4296C13.5291 24.3837 13.6536 24.3772 13.7716 24.4034C16.8261 25.1703 20.6737 25.793 25.0391 25.793C25.1374 25.793 25.1767 25.9241 25.0915 25.97C22.9023 27.3007 20.2607 28.238 17.1997 28.4084C16.6098 28.4412 16.4393 29.2344 16.9637 29.5097C24.4885 33.4689 33.0817 31.2008 37.9125 25.7537C39.1645 24.3378 41.2357 22.8368 43.1497 21.9387C44.9981 21.1128 46.8597 20.1361 48.7277 18.9824C52.7195 16.4785 54.6466 13.0109 55.4594 11.1034C55.8003 10.3037 55.4201 9.37292 54.6073 9.05173C52.5688 8.24547 48.4983 7.32778 44.2574 10.3627V10.3496ZM50.7728 11.7524C50.2353 11.7589 49.7962 11.3328 49.783 10.7953C49.7765 10.2578 50.2025 9.81865 50.74 9.80554C51.2775 9.79899 51.7167 10.2251 51.7298 10.7626C51.7363 11.3001 51.3103 11.7392 50.7728 11.7524Z"
        fill="#00CD68"
      />
      <path
        d="M140.297 0H139.235C139.039 0 138.881 0.158476 138.881 0.353966V6.27307C138.881 6.46856 139.039 6.62704 139.235 6.62704H140.297C140.492 6.62704 140.651 6.46856 140.651 6.27307V0.353966C140.651 0.158476 140.492 0 140.297 0Z"
        fill="#00CD68"
      />
      <path
        d="M122.435 4.72852H116.516C116.321 4.72852 116.162 4.88699 116.162 5.08248V5.94118C116.162 6.13667 116.321 6.29514 116.516 6.29514H122.435C122.63 6.29514 122.789 6.13667 122.789 5.94118V5.08248C122.789 4.88699 122.63 4.72852 122.435 4.72852Z"
        fill="#00CD68"
      />
      <path
        d="M136.843 2.5332H130.924C130.729 2.5332 130.57 2.69168 130.57 2.88717V3.74587C130.57 3.94136 130.729 4.09983 130.924 4.09983H136.843C137.039 4.09983 137.197 3.94136 137.197 3.74587V2.88717C137.197 2.69168 137.039 2.5332 136.843 2.5332Z"
        fill="#00CD68"
      />
      <path
        d="M128.808 5.04102H124.305C124.15 5.04102 124.023 5.16721 124.023 5.32288V6.01115C124.023 6.16681 124.15 6.29301 124.305 6.29301H128.808C128.964 6.29301 129.09 6.16681 129.09 6.01115V5.32288C129.09 5.16721 128.964 5.04102 128.808 5.04102Z"
        fill="#00CD68"
      />
      <path
        d="M128.429 2.18182L128.042 5.83292H126.594L127.013 1.86719H128.147C128.318 1.86719 128.449 2.0114 128.429 2.18182Z"
        fill="#00CD68"
      />
      <path
        d="M124.874 1.875H128.033C128.19 1.875 128.315 1.99954 128.315 2.15686V2.84513C128.315 3.00245 128.19 3.12699 128.033 3.12699H124.874C124.716 3.12699 124.592 3.00245 124.592 2.84513V2.15686C124.592 1.99954 124.716 1.875 124.874 1.875Z"
        fill="#00CD68"
      />
      <path
        d="M121.625 0.333984H117.338C117.143 0.333984 116.984 0.49246 116.984 0.687951V1.54665C116.984 1.74214 117.143 1.90061 117.338 1.90061H121.625C121.821 1.90061 121.979 1.74214 121.979 1.54665V0.687951C121.979 0.49246 121.821 0.333984 121.625 0.333984Z"
        fill="#00CD68"
      />
      <path
        d="M139.74 3.49453C141.156 3.84849 142.323 4.22868 143.254 4.60231C143.444 4.68097 143.653 4.57609 143.719 4.386C143.798 4.13035 143.909 3.80261 143.988 3.56007C144.047 3.38965 143.961 3.19955 143.791 3.12745C142.847 2.73415 141.674 2.36052 140.232 2C140.232 2 139.845 3.193 139.747 3.49453H139.74Z"
        fill="#00CD68"
      />
    </svg>
  );
};
