import { DefaultSeoProps } from "next-seo";

// GENERAL
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST || "";
export const SITE_NAME = "NEWT";
export const SITE_NAME_KANA = "NEWT（ニュート）";
export const SITE_NAME_FOR_TITLE =
  "海外旅行・国内旅行のツアーやホテル予約はNEWT(ニュート)";
export const SITE_NAME_LONG =
  "海外旅行・国内旅行のツアーやホテル予約はNEWT（ニュート）";
export const DEFAULT_DESCRIPTION =
  "かしこい、おトク、旅行アプリ「NEWT（ニュート）」。海外旅行・国内旅行をアプリでかんたんに予約できます。ぴったりの旅行がえらべて、いつでも大満足プライス。出発から帰国までサポートで安心。";
export const SITE_CATCHPHRASE =
  "海外旅行・国内旅行のツアーやホテル予約はNEWT（ニュート）";
export const SITE_URL = "https://newt.net";
export const SITE_URL_NEW =
  process.env.NEXT_PUBLIC_DOMAIN || "https://newt.net"; // TODO: replace above old SITE_URL
export const SITE_THUMBNAIL_URL = `${SITE_URL}/img/website-card.webp`;
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV || "development";
export const IS_PRODUCTION_ENV = APP_ENV === "production";
export const IS_NOT_PRODUCTION_ENV =
  APP_ENV === "local" || APP_ENV === "preview" || APP_ENV === "staging";
export const IS_DATA_SAVER_MODE =
  process.env.NEXT_PUBLIC_IS_DATA_SAVER_MODE || false;
export const COOKIE_TOKEN = "token";
export const COOKIE_AUTHORIZATION = "Authorization";
export const COOKIE_APP_ID = "app_id";
export const COOKIE_APP_VERSION = "app_version";
export const FIREBASE_TESTING_FLAG = "firebase_testing";
export const SESSION_STORAGE_SECRET =
  process.env.NEXT_PUBLIC_SESSION_STORAGE_SECRET || "";
export const LOCAL_STORAGE_SECRET =
  process.env.NEXT_PUBLIC_LOCAL_STORAGE_SECRET || "";
export const SESSION_BOOKING_KEY = "booking-params";
export const TOUR_LENGTH_IN_FIRST_VIEW = 4;
export const NEWT_SYSTEM_REQUEST_HEADER = { "newt-system-request": "true" };
export const ANONYMOUS_USER_TOKEN = "anonymous-user-token";
export const SITE_DOMAIN = process.env.NEXT_PUBLIC_DOMAIN || "../";

// WEBFLOW
export const WEBFLOW_IMAGE_URL = "https://cdn.prod.website-files.com";
export const WEBFLOW_IMAGE_CACHE_URL = "https://webflow.cdn.newt.net";

// SOCIAL NETWORKS
export const TWITTER = "@reiwatravel_jp";
export const LINE_CONCIERGE_LINK = "https://lin.ee/6wzhvP0f";

// SEO
export const DEFAULT_SEO: DefaultSeoProps = {
  title: SITE_NAME_LONG,
  description: DEFAULT_DESCRIPTION,
  openGraph: {
    title: SITE_NAME_LONG,
    description: DEFAULT_DESCRIPTION,
    type: "website",
    url: SITE_URL,
    siteName: SITE_NAME_KANA,
    images: [{ url: `${SITE_URL}/img/website-card.webp` }],
  },
  twitter: {
    handle: TWITTER,
    site: TWITTER,
    cardType: "summary_large_image",
  },
  additionalMetaTags: [
    {
      name: "keywords",
      content:
        "NEWT, ニュート, 海外旅行,国内旅行,海外ツアー,宿,海外ホテル,国内ホテル",
    },
    {
      name: "thumbnail",
      content: SITE_THUMBNAIL_URL,
    },
  ],
};

// Google Tag Manager
export const GTM_ID = "GTM-TSR6QX9";

// APP LINKS
export const IOS_DOWNLOAD =
  "https://apps.apple.com/jp/app/newt-%E3%83%8B%E3%83%A5%E3%83%BC%E3%83%88-%E3%82%B9%E3%83%9E%E3%83%BC%E3%83%88%E3%81%AB%E6%B5%B7%E5%A4%96%E6%97%85%E8%A1%8C/id1585901351";
export const ANDROID_DOWNLOAD =
  "https://play.google.com/store/apps/details?id=net.newt";
export const APP_DOWNLOAD = "https://newt.net/app";

// Security
export const BLOCKED_IPS = process.env.BLOCKED_IPS || ""; // comma separated list of IPs

// Sentry
export const USE_SENTRY = process.env.NEXT_PUBLIC_USE_SENTRY === "true";

// Authenticated LP
export const AUTHENTICATED_LPS: {
  path: string;
  loggedURL: string;
  notLoggedURL: string;
}[] = [
  {
    path: "/busan-ytc-b",
    loggedURL: "https://gr.newt.net/korea-logged",
    notLoggedURL: "https://gr.newt.net/korea-not-logged",
  },
  {
    path: "/bof-tour-kobunsya",
    loggedURL: "https://gr.newt.net/bof-tour-kobunsya-logged",
    notLoggedURL: "https://gr.newt.net/bof-tour-kobunsya-notlogged",
  },
];

// Error Message
export const DEFAULT_ERROR_MESSAGE =
  "もう一度お試しください。試しても変わらない場合はお問い合わせください。";
export const UNSAVED_ALERT_MESSAGE =
  "保存されていない変更があります。このページを読み込みますか？";
export const LEAVE_PAGE_ALERT_MESSAGE =
  "保存されていない変更があります。このページから移動しますか？";
export const PROCESSING_ERROR =
  "決済処理中です。画面が切り替わるまでお待ちください。";

// IMAGES
export const EMPTY_FAVORITE_VIDEO =
  "https://newt.imgix.net/product/empty_favorite.mp4";
export const EMPTY_RESERVATION_VIDEO =
  "https://newt.imgix.net/product/empty_reserve.mp4";
