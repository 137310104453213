import { Paragraph } from "../Paragraph";
import { Spacer } from "../Spacer";
import { Text } from "../Text";
import MaintenanceImg from "./assets/maintenance.png";
import styles from "./Maintenance.module.css";

export const Maintenance = () => {
  return (
    <div className={styles.root}>
      <div>
        <img
          className={styles.img}
          src={MaintenanceImg.src}
          alt="メンテナンス中です"
          width={240}
          height={240}
        ></img>
      </div>
      <div>
        <Text size={{ mobile: "xl", tablet: "4xl" }} weight="bold">
          メンテナンス中です
        </Text>
        <Spacer y={{ mobile: "4x", tablet: "6x" }} />
        <Paragraph size={{ mobile: "base", tablet: "lg" }}>
          現在、宿・ホテル予約はご利用いただけません。
          <br />
          ご迷惑をおかけし申し訳ありませんが、
          <br />
          今しばらくお待ちください。
        </Paragraph>
      </div>
    </div>
  );
};
