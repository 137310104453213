import { FC, useRef } from "react";
import { Button, useIsInViewport } from "@newt/ui";
import styles from "./DestinationFooter.module.css";

interface Props {
  targetRef: React.RefObject<HTMLDivElement | null>;
}

export const DestinationFooter: FC<Props> = (props) => {
  const { targetRef } = props;
  const boundaryRef = useRef<HTMLDivElement>(null);

  const { viewportBottom } = useIsInViewport(boundaryRef);
  const hideFooter = viewportBottom && viewportBottom > 0;

  const handleOnClick = () => {
    targetRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      {/** BOUNDARY to show footer */}
      <div ref={boundaryRef}></div>

      {/** FOOTER */}
      {!!viewportBottom && (
        <div
          className={[
            styles.footer,
            hideFooter ? styles.footerHidden : "",
          ].join(" ")}
        >
          <Button size="lg" onClick={handleOnClick}>
            ツアーをみる
          </Button>
        </div>
      )}
    </>
  );
};
