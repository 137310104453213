import { FC } from "react";
import { AlertVariant, Icon, Divider, Alert } from "@newt/ui";
import { CoreUserPoint_UserPointsFragment, NoticeLevel } from "@graphql/types";
import { gql } from "graphql-request";
import styles from "./UserPoint.module.css";
import Link from "next/link";
import { ROUTES } from "../../../routes";

export const USER_POINT_FRAGMENT = gql`
  fragment CoreUserPoint_UserPoints on UserPoints {
    amount
    amountForAny
    amountForTourOnly
    amountForHotelOnly
    notice {
      content
      noticeLevel
    }
  }
`;

export const VARIANT = {
  [NoticeLevel.WARNING]: AlertVariant.NORMAL,
  [NoticeLevel.ERROR]: AlertVariant.WARNING,
  [NoticeLevel.INFO]: AlertVariant.POSITIVE,
};

type UserPointProps = {
  points: CoreUserPoint_UserPointsFragment;
};

export const UserPoint: FC<UserPointProps> = ({ points }) => {
  return (
    <div className={styles.point}>
      <div className={styles.pointTitle}>
        <p>NEWTポイント</p>
        <Link
          href={ROUTES.point()}
          target="_blank"
          className={styles.pointAlertIcon}
        >
          <Icon icon="alertCircleOutline" size="sm" />
        </Link>
      </div>
      <dl className={styles.pointAmount}>
        <dt className={styles.pointAmountLabel}>合計</dt>
        <dd className={styles.pointAmountContent}>
          <Icon icon="point" color="primary" />
          <span className={styles.pointAmountValue}>
            <span>{points.amount.toLocaleString()}</span>
            ポイント
          </span>
        </dd>
      </dl>
      <Divider y="2x" />
      <div className={styles.pointSubAmountContainer}>
        <dl className={styles.pointSubAmount}>
          <dt className={styles.pointAmountLabel}>ツアー・ホテル共通</dt>
          <dd className={styles.pointSubAmountValue}>
            <span>{points.amountForAny.toLocaleString()}</span>
            ポイント
          </dd>
        </dl>
        <dl className={styles.pointSubAmount}>
          <dt className={styles.pointAmountLabel}>ツアー限定</dt>
          <dd className={styles.pointSubAmountValue}>
            <span>{points.amountForTourOnly.toLocaleString()}</span>
            ポイント
          </dd>
        </dl>
        <dl className={styles.pointSubAmount}>
          <dt className={styles.pointAmountLabel}>ホテル限定</dt>
          <dd className={styles.pointSubAmountValue}>
            <span>{points.amountForHotelOnly.toLocaleString()}</span>
            ポイント
          </dd>
        </dl>
      </div>
      {points.notice ? (
        <Alert
          className={styles.alert}
          title={points.notice.content}
          variant={VARIANT[points.notice.noticeLevel]}
          type="card"
        />
      ) : (
        <Divider y="2x" />
      )}
      <Link className={styles.pointLink} href={ROUTES.account.point()}>
        <span>ポイントの詳細</span>
        <Icon icon="chevronRight" />
      </Link>
    </div>
  );
};
